<template>
  <div>
    <b-col cols="12">
      <h2>Цены</h2>
      <div class="d-flex">
        <b-card class="mb-4 mt-4 mr-4">
          <b-overlay :show="loadingServiceFee">
            <b-form-group label="% Сервисного сбора">
              <b-form-input v-model="serviceFee" class="mb-4"/>
              <b-button variant="success" @click="saveServiceFee">Сохранить</b-button>
            </b-form-group>
          </b-overlay>
        </b-card>
<!--        <b-card class="mb-4 mt-4 mr-4">
          <b-overlay :show="loadingCashBoxFee">
            <b-form-group label="% Кассового сбора">
              <b-form-input v-model="cashBoxFee" class="mb-4"/>
              <b-button variant="success" @click="saveCashBoxFee">Сохранить</b-button>
            </b-form-group>
          </b-overlay>
        </b-card>-->
        <b-card class="mb-4 mt-4">
          <b-overlay :show="loadingChildTicketDiscount">
            <b-form-group label="% Скидки детского билета">
              <b-form-input v-model="childTicketDiscount" class="mb-4"/>
              <b-button variant="success" @click="saveChildTicketDiscount">Сохранить</b-button>
            </b-form-group>
          </b-overlay>
        </b-card>
      </div>
    </b-col>
    <b-col cols="12">
      <div class="d-flex">
        <b-card class="mb-4 mt-4 mr-4">
          <b-overlay :show="loadingDirection">
            <b-form-group label="Начальный пункт">
              <b-form-select v-model="fromId">
                <option :value="null">Выберите пункт отправления</option>
                <option v-for="(point, index) in points" :value="point.id" :key="index">{{ point.name }}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Конечный пункт">
              <b-form-select v-model="toId">
                <option :value="null">Выберите конечный пункт</option>
                <option v-for="(point, index) in points" :value="point.id" :key="index">{{ point.name }}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Цена">
              <b-form-input v-model="directionPrice"/>
            </b-form-group>
            <b-form-checkbox v-model="isActive" class="mb-4">
              Активен
            </b-form-checkbox>
            <b-button variant="success" @click="saveDirectionPrice">Изменить цену</b-button>
          </b-overlay>
        </b-card>
      </div>
    </b-col>
  </div>
</template>

<script>
import _ from 'lodash'
import PricesApi from "@/api/prices.api"
import DeparturesApi from "@/api/departures.api"
import {toRubles} from "@/utils/money";

export default {
  name: "Prices",
  data() {
    return {
      points: [],

      loadingDirection: false,
      loadingServiceFee: false,
      loadingCashBoxFee: false,
      loadingChildTicketDiscount: false,

      fromId: null,
      toId: null,
      directionPrice: null,
      isActive: null,

      cashBoxFee: null,
      serviceFee: null,
      childTicketDiscount: null
    }
  },
  watch: {
    fromId: {
      handler: function () {
        this.directionPrice = null
        this.loadDirectionPrice()
      },
      deep: true
    },
    toId: {
      handler: function () {
        this.directionPrice = null
        this.loadDirectionPrice()
      },
      deep: true
    }
  },
  mounted() {
    this.loadingDirection = true
    this.loadingServiceFee = true
    this.loadingCashBoxFee = true
    this.loadingChildTicketDiscount = true

    PricesApi.getPrices().then(response => {
      if (response.data.status === 'success') {
        this.cashBoxFee = response.data.data.cashBoxFee
        this.serviceFee = response.data.data.serviceFee
        this.childTicketDiscount = response.data.data.childTicketDiscount
      }
    }).finally(() => {
      this.loadingServiceFee = false
      this.loadingCashBoxFee = false
      this.loadingChildTicketDiscount = false
    })

    DeparturesApi.getAllPoints().then(response => {
        this.points = response.data
    }).finally(() => {
      this.loadingDirection = false
    })
  },
  methods: {
    loadDirectionPrice: _.debounce(function() {
      if (!this.fromId)
        return;

      if (!this.toId)
        return;

      if (this.fromId === this.toId)
        return;

      this.loadingDirection = true

      PricesApi.getDirectionPrice({ fromId: this.fromId, toId: this.toId }).then(response => {
          this.directionPrice = response.data.price ? toRubles(response.data.price) : null
          this.isActive = response.data.isActive;
      }).finally(() => {
        this.loadingDirection = false
      })
    }, 500),
    saveDirectionPrice() {
      if (!this.fromId)
        return;

      if (!this.toId)
        return;

      if (this.fromId === this.toId)
        return;

      this.loadingDirection = true

      PricesApi.saveDirectionPrice({ fromId: this.fromId, toId: this.toId, price: this.directionPrice, isActive: this.isActive })
        .finally(() => {
        this.loadingDirection = false
      })
    },
    saveServiceFee() {
      if (!this.serviceFee)
        return;

      this.loadingServiceFee = true
      PricesApi.saveServiceFee({ value: this.serviceFee })
      .finally(() => {
        this.loadingServiceFee = false
      })
    },
    saveCashBoxFee() {
      if (!this.cashBoxFee)
        return;

      this.loadingCashBoxFee = true
      PricesApi.saveCashBoxFee({ value: this.cashBoxFee })
      .finally(() => {
        this.loadingCashBoxFee = false
      })
    },
    saveChildTicketDiscount() {
      if (!this.childTicketDiscount)
        return;

      this.loadingChildTicketDiscount = true
      PricesApi.saveChildTicketDiscount({ value: this.childTicketDiscount })
      .finally(() => {
        this.loadingChildTicketDiscount = false
      })
    },
  },
  components: {
  }
}
</script>

<style scoped>
.action-icon {
  margin: 4px;
  cursor: pointer;
}
</style>